import React from 'react'
import { IContentDetailsProps } from '../contracts/IContentDetailsProps'

const ContentDetails = (props: IContentDetailsProps) => {
  // const paragraphs = props.content?.split('\u000A \u000D')

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              {props.category}
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {props.title}
            </span>
          </h1>
          {props.tagline &&
            <p className="mt-8 text-xl text-gray-500 leading-8">
            {props.tagline.split('\u000A \u000D').map((paragraph, i) => (
            <p key={i} className="mb-2 text-xl text-gray-500">{paragraph}</p>
            ))}
            </p>
          }
        </div>
        <div className="mt-12 lg:mt-24 prose prose-indigo prose-lg text-gray-500 mx-auto">
          {props.content &&
            <div className="relative">
              <dl className="mt-10 space-y-10">
              {props.content.map((paragraph, i) => (
                <div key={i}>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{paragraph}</dd>
                </div>
              ))}
              </dl>
            </div>
          }

          {props.quote &&
            <blockquote>
              <p>
                {props.quote}
              </p>
            </blockquote>
          }
          
          {props.mainFeatures && 
            <div className="relative">
              <dl className="mt-10 space-y-10">
                {props.mainFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sekyee-blue-light text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    {item.description.split('\u000A \u000D').map((paragraph, i) => (
                      <dd key={i} className="mt-2 mb-6 ml-16 text-base text-gray-500">{paragraph}</dd>
                    ))}
                  </div>
                ))}
              </dl>
            </div>
          }
          {props.imageUri && 
            <figure>
              <img
                className="w-full rounded-lg"
                src={props.imageUri}
                alt=""
                width={1310}
                height={873}
              />
              <figcaption>{props.caption}</figcaption>
            </figure>
          }
        </div>
        <div className="mt-20 text-lg max-w-full text-center mx-auto">
          {props.footer &&
            <p>
              {props.footer}
            </p>
          }
        </div>
      </div>
      </div>
    </div>      
  )
}

export default ContentDetails
import React from 'react'
import { PageProps } from 'gatsby'

import { GlobeAltIcon, LightningBoltIcon } from '@heroicons/react/outline'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ContentDetails from '../components/ContentDetails'

const mainFeatureItems = [
  {
    id: 1,
    name: 'Public Cloud',
    description:
      `Control costs, ensure security and experience complete flexibility with tailored public cloud hosting. We utilise main public cloud providers such as Microsoft Azure, Amazon Webs Services [AWS] to help assist you with the full implementation and migration of your services and infrastructure with ongoing monitoring, management and support.`,
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'Private Cloud',
    description:
      `Achieve the highest standards of security and total control with a bespoke, fully managed private cloud solution, architected to meet your unique hosting requirements. Best suited for businesses with dynamic computing needs that require complete control, typically to meet regulatory compliance, security and governance needs.`,
    icon: LightningBoltIcon,
  },
  {
    id: 3,
    name: 'Hybrid Cloud',
    description:
      `Combine on-premises infrastructure with ultra-high-performance private cloud providing greater flexibility, cost saving and more options for your business.`,
    icon: LightningBoltIcon,
  },
  {
    id: 4,
    name: 'Multi-Cloud',
    description:
      `Harness the power of multiple public cloud providers. Take advantage of each platform’s unique offerings, migrating each workload to the perfect platform.`,
    icon: LightningBoltIcon,
  }
]

const CloudServices = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <ContentDetails
          title="CLOUD SERVICES"
          mainFeatures={mainFeatureItems}
          footer="Please contact us for more information." />
      </main>
    
      <Footer />
    </div>
  )
}

export default CloudServices